import {Pagination} from "antd";
import React from "react";

const index = ({
  onChange,
  total,
  showTotal,
  defaultCurrent,
  defaultPageSize,
}) => {
  return (
    <Pagination
      onChange={onChange}
      total={total}
      showTotal={showTotal}
      defaultCurrent={defaultCurrent}
      defaultPageSize={defaultPageSize}
    />
  );
};

export default index;

import {Checkbox, Input} from "antd";
import React from "react";
import {getCategory} from "../../apis/category";
import {categorySerialize} from "../../util/helper";
import Papa from "papaparse";
import {convertArrayToCSV} from "convert-array-to-csv";

const index = ({
  setCategory,
  setCategoryTotalCount,
  setL2orL3,
  setSelectedSourceMap,
  setSourceFileContent,
  setFile,
  selectedSourceMap,
  sourceFileContent,
  isL2orL3,
}) => {
  const onChangeL1orL2 = (event, item, key, index) => {
    setL2orL3({item, key, index});
  };
  const columns = [
    {
      title: "Master Level1",
      dataIndex: "masterLevel1",
      key: "masterLevel1",
    },
    {
      title: "Master Level2",
      dataIndex: "masterLevel2",
      key: "masterLevel2",
      filterDropdown: ({
        setSelectedKeys,
        selectedKeys,
        confirm,
        clearFilters,
      }) => {
        return (
          <>
            <Input
              autoFocus
              placeholder="Type text here"
              value={selectedKeys[0]}
              onChange={(e) => {
                setSelectedKeys(e.target.value ? [e.target.value] : []);
                confirm({closeDropdown: false});
              }}
              onPressEnter={async () => {
                let data = await getCategory({l2Name: selectedKeys[0]});
                setCategory(
                  categorySerialize(data?.data?.category, onChangeL1orL2)
                );
                setCategoryTotalCount(data?.data?.count);
                confirm();
              }}
              onBlur={() => {
                confirm();
              }}
            ></Input>
          </>
        );
      },
    },
    {
      title: "Master Level3",
      dataIndex: "masterLevel3",
      key: "masterLevel3",
      filterDropdown: ({
        setSelectedKeys,
        selectedKeys,
        confirm,
        clearFilters,
      }) => {
        return (
          <>
            <Input
              autoFocus
              placeholder="Type text here"
              value={selectedKeys[0]}
              onChange={(e) => {
                setSelectedKeys(e.target.value ? [e.target.value] : []);
                confirm({closeDropdown: false});
              }}
              onPressEnter={async () => {
                let data = await getCategory({l3Name: selectedKeys[0]});
                setCategory(
                  categorySerialize(data?.data?.category, onChangeL1orL2)
                );
                setCategoryTotalCount(data?.data?.count);
                confirm();
              }}
              onBlur={() => {
                confirm();
              }}
            ></Input>
          </>
        );
      },
    },
  ];
  const sourceColumns = [
    {title: "SourceLevel1", dataIndex: "MasterLevel1"},
    {title: "SourceLevel2", dataIndex: "MasterLevel2"},
    {title: "SourceLevel3", dataIndex: "MasterLevel3"},
    {
      title: "MasterCategoryId",
      dataIndex: "MasterCategoryId",
      sorter: (a, b) => {
        return a.ML1 !== null;
      },
    },
    {
      title: "MasterLevel1",
      dataIndex: "ML1",
    },
    {
      title: "MasterLevel2",
      dataIndex: "ML2",
    },
  ];
  const addCheckBox = ({key, title, item, checked, defaultChecked, index}) => (
    <div>
      {title}
      <Checkbox
        key={key}
        onChange={(e) => onChangeL1orL2(e, item, key, index)}
        style={{marginLeft: "30px"}}
        defaultChecked={defaultChecked}
        checked={checked}
      />
    </div>
  );
  const rowSelectionSourceMapping = () => {
    return {
      type: "radio",
      onChange: (selectedRowKeys, selectedRows) => {
        setSelectedSourceMap(selectedRows);
      },
      getCheckboxProps: (record) => ({
        disabled: record.name === "Disabled User",
        // Column configuration not to be checked
        id: record.id,
      }),
    };
  };

  const handleMapClick = () => {
    let sourceFileContentTemp = [...sourceFileContent];

    if (isL2orL3.key === "L2") {
      sourceFileContentTemp[selectedSourceMap[0].key].MasterCategoryId =
        isL2orL3.item.L2 || isL2orL3.item.catId;
      sourceFileContentTemp[selectedSourceMap[0].key].ML1 =
        isL2orL3.item.Industry || isL2orL3.item.masterLevel1;
      sourceFileContentTemp[selectedSourceMap[0].key].ML2 =
        isL2orL3.item.Category || isL2orL3.item.masterLevel2.props.children[0];
    }
    if (isL2orL3.key === "L3") {
      sourceFileContentTemp[selectedSourceMap[0].key].MasterCategoryId =
        isL2orL3.item.L3 || isL2orL3.item.subId;
      sourceFileContentTemp[selectedSourceMap[0].key].ML1 =
        isL2orL3.item.Category || isL2orL3.item.masterLevel2.props.children[0];
      sourceFileContentTemp[selectedSourceMap[0].key].ML2 =
        isL2orL3.item.Subcategory ||
        isL2orL3.item.masterLevel3.props.children[0];
    }

    setSourceFileContent(sourceFileContentTemp);
  };
  const onPageChange = async (page, pageSize) => {
    let data = await getCategory({page: page, size: pageSize});
    setCategory(categorySerialize(data?.data?.category, onChangeL1orL2));
    setCategoryTotalCount(data.data.count);
  };
  const onFileUpload = (newFile) => {
    if (newFile) {
      Papa.parse(newFile, {
        header: true,
        skipEmptyLines: true,
        complete: function (results) {
          results?.data?.forEach((item, index) => {
            if (
              !item.MasterLevel1 &&
              !item.MasterLevel2 &&
              !item.MasterLevel3
            ) {
              delete results.data[index];
            }
          });
          results.data = results.data.filter((item) => item);
          results?.data?.forEach((item, index) => {
            item.id = index;
            item.key = index;
            item.MasterCategoryId = null;
            item.ML1 = null;
            item.ML2 = null;
          });

          setSourceFileContent(results.data);
        },
      });
      setFile(newFile);
    }
  };
  const handleDownload = () => {
    let sourceFileContentTemp = JSON.parse(JSON.stringify(sourceFileContent));

    sourceFileContentTemp.forEach((item) => {
      delete item.MasterLevel1;
      delete item.MasterLevel1Id;
      delete item.MasterLevel2Id;
      delete item.MasterLevel2;
      item.SourceCategoryName = item.MasterLevel3;
      item.SourceCategoryID = item.MasterLevel3;
      delete item.MasterLevel3;
      delete item.MasterLevel3Id;
      delete item.id;
      delete item.key;
      item.MasterLevel1 = item.ML1;
      item.MaterLevel2 = item.ML2;
      delete item.ML1;
      delete item.ML2;
      item.type = "primary";
    });

    const csvFromArrayOfObjects = convertArrayToCSV(sourceFileContentTemp);
    const blob = new Blob([csvFromArrayOfObjects], {type: "text/csv"});
    const url = URL.createObjectURL(blob);
    const link = document.createElement("a");
    link.download = "Mapping.csv";
    link.href = url;
    link.click();
  };
  return {
    columns,
    sourceColumns,
    addCheckBox,
    rowSelectionSourceMapping,
    handleMapClick,
    onPageChange,
    onFileUpload,
    handleDownload,
  };
};

export default index;

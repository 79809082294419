import "./App.css";
import React from "react";
import {QueryClient, QueryClientProvider} from "@tanstack/react-query";
import Index from "./pages";

function App() {
  const queryClient = new QueryClient();

  return (
    <>
      <QueryClientProvider client={queryClient}>
        <Index />
      </QueryClientProvider>
    </>
  );
}

export default App;

/* eslint-disable no-unused-vars */
import React, {useEffect, useMemo, useRef, useState} from "react";
import {useQuery} from "@tanstack/react-query";
import axios from "axios";

import {MuiFileInput} from "mui-file-input";
import Papa from "papaparse";
import {Button, Checkbox, Input, Pagination, Table} from "antd";
import {getCategory} from "../apis/category";
import {categorySerialize} from "../util/helper";
import CategoryMappingPage from "../components/categoryMappingPage";

// const Index = () => {
//   const rowVirtualizerInstanceRef = useRef(null);

//   const [sorting, setSorting] = useState([]);
//   const [file, setFile] = React.useState(null);
//   const [category, setCategory] = React.useState(null);
//   const [isL2orL3, setL2orL3] = React.useState(null);
//   const [categoryTotalCount, setCategoryTotalCount] = React.useState(null);
//   const [sourceFileContent, setSourceFileContent] = React.useState(null);
//   const [selectedSourceMap, setSelectedSourceMap] = React.useState();

//   const optionMenu = ["MasterLevel2", "MasterLevel3"];
//   const handleChange = (newFile) => {
//     if (newFile) {
//       Papa.parse(newFile, {
//         header: true,
//         skipEmptyLines: true,
//         complete: function (results) {
//           results?.data?.map((item, index) => {
//             item.id = index;
//             item.key = index;
//           });
//
//           setSourceFileContent(results.data);
//         },
//       });
//       setFile(newFile);
//     }
//   };

//   const onChangeL1orL2 = (event, item, key, index) => {
//
//     setL2orL3({item, key, index});
//   };

//   let {isLoading, data} = useQuery({
//     queryKey: ["repoData"],
//     queryFn: () =>
//       axios
//         .post("http://localhost:3005/server-api/getCategoryList")
//         .then((res) => {
//           setCategoryTotalCount(res?.data?.data?.count);
//           return res.data.data.category.map((item, index) => {
//             return {
//               masterLevel1: item.Industry,
//               masterLevel2: (
//                 <div>
//                   {item.Category}
//                   <Checkbox
//                     key="L2"
//                     onChange={(e) => onChangeL1orL2(e, item, "L2", index)}
//                     style={{marginLeft: "30px"}}
//                   />
//                 </div>
//               ),
//               masterLevel3: (
//                 <div>
//                   {item.Subcategory}
//                   <Checkbox
//                     key="L3"
//                     onChange={(e) => onChangeL1orL2(e, item, "L3", index)}
//                     style={{marginLeft: "30px"}}
//                   />
//                 </div>
//               ),
//               indId: item?.L1,
//               catId: item.L2,
//               subId: item.L3,
//             };
//           });
//         }),
//     enabled: !category?.length > 0,
//   });
//   useEffect(() => {
//     if (category?.length > 0) {
//       let categoryTemp = [...category];
//       categoryTemp?.map((item, index) => {
//
//         if (isL2orL3?.key === "L2") {
//           item.masterLevel3 = (
//             <div>
//               {item.masterLevel3.props.children[0]}
//               <Checkbox
//                 key="L3"
//                 onChange={(e) => onChangeL1orL2(e, item, "L3", index)}
//                 style={{marginLeft: "30px"}}
//                 checked={false}
//                 defaultChecked={false}
//               />
//             </div>
//           );
//           if (index !== isL2orL3?.index) {
//
//             item.masterLevel2 = (
//               <div>
//                 {item.masterLevel2.props.children[0]}
//                 <Checkbox
//                   key="L2"
//                   onChange={(e) => onChangeL1orL2(e, item, "L2", index)}
//                   style={{marginLeft: "30px"}}
//                   checked={false}
//                   defaultChecked={false}
//                 />
//               </div>
//             );
//           } else {
//             item.masterLevel2 = (
//               <div>
//                 {item.masterLevel2.props.children[0]}
//                 <Checkbox
//                   key="L2"
//                   onChange={(e) => onChangeL1orL2(e, item, "L2", index)}
//                   style={{marginLeft: "30px"}}
//                   checked={true}
//                   defaultChecked={true}
//                 />
//               </div>
//             );
//           }
//         }
//         if (isL2orL3?.key === "L3") {
//
//           item.masterLevel2 = (
//             <div>
//               {item.masterLevel2.props.children[0]}
//               <Checkbox
//                 key="L2"
//                 onChange={(e) => onChangeL1orL2(e, item, "L2", index)}
//                 style={{marginLeft: "30px"}}
//                 checked={false}
//                 defaultChecked={false}
//               />
//             </div>
//           );
//           if (index !== isL2orL3?.index) {
//             item.masterLevel3 = (
//               <div>
//                 {item.masterLevel3.props.children[0]}
//                 <Checkbox
//                   key="L2"
//                   onChange={(e) => onChangeL1orL2(e, item, "L3", index)}
//                   style={{marginLeft: "30px"}}
//                   checked={false}
//                   defaultChecked={false}
//                 />
//               </div>
//             );
//           } else {
//             item.masterLevel3 = (
//               <div>
//                 {item.masterLevel3.props.children[0]}
//                 <Checkbox
//                   key="L2"
//                   onChange={(e) => onChangeL1orL2(e, item, "L3", index)}
//                   style={{marginLeft: "30px"}}
//                   checked={true}
//                   defaultChecked={true}
//                 />
//               </div>
//             );
//           }
//         }
//       });

//       setCategory(categoryTemp);
//     } else {
//       setCategory(data);
//     }
//     // eslint-disable-next-line react-hooks/exhaustive-deps
//   }, [data, isL2orL3?.index, isL2orL3?.key, isL2orL3?.key]);

//   useEffect(() => {
//     //scroll to the top of the table when the sorting changes
//     try {
//       rowVirtualizerInstanceRef.current?.scrollToIndex?.(0);
//     } catch (error) {
//       console.error(error);
//     }
//   }, [sorting]);

//   const columns = [
//     {
//       title: "Master Level1",
//       dataIndex: "masterLevel1",
//       key: "masterLevel1",
//     },
//     {
//       title: "Master Level2",
//       dataIndex: "masterLevel2",
//       key: "masterLevel2",
//       filterDropdown: ({
//         setSelectedKeys,
//         selectedKeys,
//         confirm,
//         clearFilters,
//       }) => {
//         return (
//           <>
//             <Input
//               autoFocus
//               placeholder="Type text here"
//               value={selectedKeys[0]}
//               onChange={(e) => {
//                 setSelectedKeys(e.target.value ? [e.target.value] : []);
//                 confirm({closeDropdown: false});
//               }}
//               onPressEnter={async () => {
//                 let data = await getCategory({l2Name: selectedKeys[0]});
//                 setCategory(
//                   categorySerialize(data?.data?.category, onChangeL1orL2)
//                 );
//                 setCategoryTotalCount(data?.data?.count);
//                 confirm();
//               }}
//               onBlur={() => {
//                 confirm();
//               }}
//             ></Input>
//           </>
//         );
//       },
//     },
//     {
//       title: "Master Level3",
//       dataIndex: "masterLevel3",
//       key: "masterLevel3",
//       filterDropdown: ({
//         setSelectedKeys,
//         selectedKeys,
//         confirm,
//         clearFilters,
//       }) => {
//         return (
//           <>
//             <Input
//               autoFocus
//               placeholder="Type text here"
//               value={selectedKeys[0]}
//               onChange={(e) => {
//                 setSelectedKeys(e.target.value ? [e.target.value] : []);
//                 confirm({closeDropdown: false});
//               }}
//               onPressEnter={async () => {
//                 let data = await getCategory({l3Name: selectedKeys[0]});
//                 setCategory(
//                   categorySerialize(data?.data?.category, onChangeL1orL2)
//                 );
//                 setCategoryTotalCount(data?.data?.count);
//                 confirm();
//               }}
//               onBlur={() => {
//                 confirm();
//               }}
//             ></Input>
//           </>
//         );
//       },
//     },
//   ];

//   const sourceColumns = [
//     {title: "SourceLevel1", dataIndex: "MasterLevel1"},
//     {title: "SourceLevel2", dataIndex: "MasterLevel2"},
//     {title: "SourceLevel3", dataIndex: "MasterLevel3"},

//     {
//       title: "MasterCategoryId",
//       dataIndex: "MasterCategoryID",
//     },
//     {
//       title: "MasterCategoryName",
//       dataIndex: "MasterCategoryName",
//     },
//   ];

//   const handleMapClick = () => {
//     let sourceFileContentTemp = [...sourceFileContent];
//     if (isL2orL3.key === "L2") {
//       sourceFileContentTemp[selectedSourceMap[0].key].MasterCategoryID =
//         isL2orL3.item.L2 || isL2orL3.item.catId;
//       sourceFileContentTemp[selectedSourceMap[0].key].MasterCategoryName =
//         isL2orL3.item.category || isL2orL3.item.masterLevel2.props.children[0];
//     }
//     if (isL2orL3.key === "L3") {
//       sourceFileContentTemp[selectedSourceMap[0].key].MasterCategoryID =
//         isL2orL3.item.L3 || isL2orL3.item.subId;
//       sourceFileContentTemp[selectedSourceMap[0].key].MasterCategoryName =
//         isL2orL3.item.Subcategory ||
//         isL2orL3.item.masterLevel3.props.children[0];
//     }

//     setSourceFileContent(sourceFileContentTemp);
//   };
//   return (
//     <>
//       <Table
//         rowSelection={{
//           type: "radio",
//           onChange: (selectedRowKeys, selectedRows) => {
//             setSelectedSourceMap(selectedRows);
//           },
//           getCheckboxProps: (record) => ({
//             disabled: record.name === "Disabled User",
//             // Column configuration not to be checked
//             id: record.id,
//           }),
//         }}
//         columns={sourceColumns}
//         dataSource={sourceFileContent}
//       />

//       <div style={{display: "flex", justifyContent: "center"}}>
//         <Button type="primary" onClick={handleMapClick}>
//           Map
//         </Button>
//       </div>
//       <Table dataSource={category} columns={columns} pagination={false} />
//       <Pagination
//         onChange={async (page, pageSize) => {
//           let data = await getCategory({page: page, size: pageSize});
//           setCategory(categorySerialize(data?.data?.category, onChangeL1orL2));
//           setCategoryTotalCount(data.data.count);
//         }}
//         total={categoryTotalCount}
//         showTotal={(total, range) =>
//           `${range[0]}-${range[1]} of ${total} items`
//         }
//         defaultPageSize={20}
//         defaultCurrent={1}
//       />
//       <MuiFileInput value={file} onChange={handleChange} />
//     </>
//   );
// };

const Index = () => {
  return <CategoryMappingPage />;
};

export default Index;

import axios from "axios";
import {API_BASE_URL} from "../util/helper";

export const getCategory = async ({l1Name, l2Name, l3Name, page, size}) => {
  const option = {
    url: `${API_BASE_URL}getCategoryList`,
    method: "POST",
    data: {
      l1Name,
      l2Name,
      l3Name,
      page,
      size,
    },
  };
  const data = await axios(option).catch((err) => {
    return err.response;
  });
  return data.data;
};

import {Button} from "antd";
import React from "react";

const index = ({type, onClick, children, ...props}) => {
  return (
    <Button type={type} onClick={onClick} {...props}>
      {children}
    </Button>
  );
};

export default index;

import {Checkbox} from "antd";

export const categorySerialize = (data, onChangeL1orL2) => {
  data?.forEach((item, index) => {
    item.masterLevel1 = item.Industry;
    item.masterLevel2 = (
      <div>
        {item.Category}
        <Checkbox
          key="L2"
          onChange={(e) => onChangeL1orL2(e, item, "L2", index)}
          style={{marginLeft: "30px"}}
          checked={false}
          defaultChecked={false}
        />
      </div>
    );
    item.masterLevel3 = (
      <div>
        {item.Subcategory}
        <Checkbox
          key="L3"
          onChange={(e) => onChangeL1orL2(e, item, "L3", index)}
          style={{marginLeft: "30px"}}
          checked={false}
          defaultChecked={false}
        />
      </div>
    );
  });
  return data;
};
export const API_BASE_URL = `${process.env.REACT_APP_API_URL}`;

import React, {useEffect} from "react";
import AntdTable from "../../shared/components/AntdTable/index.jsx";
import useCategoryMapPage from "./useCategoryMapPage.js";
import axios from "axios";
import {useQuery} from "@tanstack/react-query";
import AntdButton from "../../shared/components/AntdButton/index.jsx";
import AntdPagination from "../../shared/components/AntdPagination/index.jsx";
import MuiFileInput from "../../shared/components/MuiFileInput/index.js";
import {API_BASE_URL} from "../../util/helper.js";

const CategoryMappingPage = () => {
  const [category, setCategory] = React.useState(null);
  const [file, setFile] = React.useState(null);
  const [isL2orL3, setL2orL3] = React.useState(null);
  const [categoryTotalCount, setCategoryTotalCount] = React.useState(null);
  const [sourceFileContent, setSourceFileContent] = React.useState(null);
  const [selectedSourceMap, setSelectedSourceMap] = React.useState();

  const {
    sourceColumns,
    columns,
    addCheckBox,
    rowSelectionSourceMapping,
    handleMapClick,
    onPageChange,
    onFileUpload,
    handleDownload,
  } = useCategoryMapPage({
    setCategory,
    setCategoryTotalCount,
    setL2orL3,
    setSelectedSourceMap,
    setSourceFileContent,
    setFile,
    isL2orL3,
    selectedSourceMap,
    sourceFileContent,
  });
  let {data} = useQuery({
    queryKey: ["repoData"],
    queryFn: () =>
      axios.post(`${API_BASE_URL}getCategoryList`).then((res) => {
        setCategoryTotalCount(res?.data?.data?.count);
        return res.data.data.category.map((item, index) => {
          return {
            masterLevel1: item.Industry,
            masterLevel2: addCheckBox({
              title: item.Category,
              item,
              key: "L2",
              index,
            }),
            masterLevel3: addCheckBox({
              title: item.Subcategory,
              item,
              key: "L3",
              index,
            }),
            indId: item?.L1,
            catId: item.L2,
            subId: item.L3,
          };
        });
      }),
    enabled: !category?.length > 0,
  });

  useEffect(() => {
    if (category?.length > 0) {
      let categoryTemp = [...category];
      categoryTemp?.forEach((item, index) => {
        if (isL2orL3?.key === "L2") {
          item.masterLevel3 = addCheckBox({
            title: item.masterLevel3.props.children[0],
            key: "L3",
            checked: false,
            defaultChecked: false,
            index,
            item,
          });
          if (index !== isL2orL3?.index) {
            item.masterLevel2 = addCheckBox({
              item,
              key: "L2",
              checked: false,
              defaultChecked: false,
              index,
              title: item.masterLevel2.props.children[0],
            });
          } else {
            item.masterLevel2 = addCheckBox({
              key: "L2",
              item,
              checked: true,
              defaultChecked: true,
              title: item.masterLevel2.props.children[0],
              index,
            });
          }
        }
        if (isL2orL3?.key === "L3") {
          item.masterLevel2 = addCheckBox({
            item,
            key: "L2",
            index,
            checked: false,
            defaultChecked: false,
            title: item.masterLevel2.props.children[0],
          });
          if (index !== isL2orL3?.index) {
            item.masterLevel3 = addCheckBox({
              item,
              title: item.masterLevel3.props.children[0],
              key: "L3",
              index,
              checked: false,
              defaultChecked: false,
            });
          } else {
            item.masterLevel3 = addCheckBox({
              item,
              title: item.masterLevel3.props.children[0],
              key: "L3",
              index,
              checked: true,
              defaultChecked: true,
            });
          }
        }
      });
      setCategory(categoryTemp);
    } else {
      setCategory(data);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data, isL2orL3?.index, isL2orL3?.key, isL2orL3?.key]);
  return (
    <>
      <div style={{marginLeft: "auto", marginRight: "auto", width: "414px"}}>
        <p
          style={{
            fontSize: "20px",
            fontWeight: "800",
          }}
        >
          Select Source Category Mapping file...
        </p>
        <MuiFileInput value={file} onChange={onFileUpload} />
      </div>
      <AntdTable
        rowSelection={rowSelectionSourceMapping()}
        columns={sourceColumns}
        dataSource={sourceFileContent}
      />
      <div
        style={{
          display: "flex",
          justifyContent: "space-around",
          margin: "20px 0px",
        }}
      >
        <AntdButton
          type="primary"
          onClick={handleMapClick}
          style={{height: "42px", width: "132px"}}
        >
          Map
        </AntdButton>
        <AntdButton
          type="primary"
          onClick={handleDownload}
          style={{height: "42px", width: "132px"}}
          danger
        >
          Download
        </AntdButton>
      </div>
      <AntdTable
        dataSource={category}
        columns={columns}
        pagination={false}
        sorter
      />
      <AntdPagination
        onChange={onPageChange}
        defaultPageSize={20}
        defaultCurrent={1}
        total={categoryTotalCount}
      />
    </>
  );
};

export default CategoryMappingPage;
